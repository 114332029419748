
import { defineComponent } from "vue";
import AppPage from "../components/AppPage.vue";
import AccountInfo, { AccountInfoItem } from "../components/AccountInfo.vue";
import Chart from "../components/Chart.vue";
import Loader from "../components/Preloader.vue";
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";

interface DataModel {
  loading: boolean;
  infoRub: AccountInfoItem[];
  infoUsd: AccountInfoItem[];
  infoEur: AccountInfoItem[];
  news: Array<News>;
  charts: Array<ChartItem>;
  campaigns: AccountInfoItem[];
  epc: any;
  colors_news: any;
  colors_devices: any;
  devices: any;
}

interface News {
  url: string;
  title: string;
  category: string;
}

interface Category {
  name: string;
  value: number;
  color: string;
}

interface ChartItem {
  title: string;
  chart_title: string;
  categories: Array<Category>;
}

export default defineComponent({
  components: {
    AppPage,
    AccountInfo,
    Chart,
    Loader,
  },
  data(): DataModel {
    return {
      loading: true,
      infoRub: [],
      infoUsd: [],
      infoEur: [],
      news: [],
      charts: [],
      campaigns: [],
      epc: [],
      colors_news: {
        События: "#3F5171",
        Спорт: "#05C985",
        Политика: "#FF9C31",
        "Шоу-бизнес": "#843FA0",
        Здоровье: "#1F8EFA",
        Спецпроекты: "#EE423D",
        Кухня: "#ff57db",
        Другие: "#8AA7E0",
      },
      colors_devices: {
        ПК: "#05C985",
        Планшет: "#EE423D",
        Телефон: "#FF9C31",
        Другие: "#1F8EFA",
      },
      devices: [],
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  watch: {
    user() {
      this.load();
    },
  },
  methods: {
    async load() {
      this.loading = true;

      try {
        const [
          { data: news },
          { data: infoRub },
          { data: infoUsd },
          { data: infoEur },
          { data: campaigns },
          { data: epc },
          { data: diagram },
          { data: traffic },
        ] = await Promise.all([
          axios.get("/api/news/top", {
            params: {
              period: [
                moment().startOf("day").toISOString(),
                moment().endOf("day").toISOString(),
              ],
            },
          }),
          axios.get("/api/profile/account-info", { params: { currency: "RUB" } }),
          axios.get("/api/profile/account-info", { params: { currency: "USD" } }),
          axios.get("/api/profile/account-info", { params: { currency: "EUR" } }),
          axios.get("/api/dashboard/campaigns-count"),
          axios.get("/api/dashboard/epc"),
          axios.get("/api/news/diagram", {
            params: {
              period: [
                moment().endOf("day").subtract(7, "days").toISOString(),
                moment().endOf("day").toISOString(),
              ],
            },
          }),
          axios.get("/api/dashboard/traffic", {
            params: {
              period: [
                moment().endOf("day").subtract(7, "days").toISOString(),
                moment().endOf("day").toISOString(),
              ],
            },
          }),
        ]);

        this.epc = [{ title: "EPC", amount: epc, currency: "USD" }];

        this.infoRub = [
          { title: "Доступно", amount: infoRub.available, currency: "RUB" },
          { title: "В ожидании", amount: infoRub.pending, currency: "RUB" },
          { title: "Всего", amount: infoRub.total, currency: "RUB" },
          { title: "Выплачено", amount: infoRub.paid, currency: "RUB" },
        ];

        this.infoUsd = [
          { title: "Доступно", amount: infoUsd.available, currency: "USD" },
          { title: "В ожидании", amount: infoUsd.pending, currency: "USD" },
          { title: "Всего", amount: infoUsd.total, currency: "USD" },
          { title: "Выплачено", amount: infoUsd.paid, currency: "USD" },
        ];

        this.infoEur = [
          { title: "Доступно", amount: infoEur.available, currency: "EUR" },
          { title: "В ожидании", amount: infoEur.pending, currency: "EUR" },
          { title: "Всего", amount: infoEur.total, currency: "EUR" },
          { title: "Выплачено", amount: infoEur.paid, currency: "EUR" },
        ];

        this.news = news.map((x: any) => ({
          ...x,
          url: `https://${x.domain}/${x.id}`,
        }));

        this.charts = [
          {
            title: "Категории новостей за последние 7&nbsp;дней",
            chart_title: "Все новости",
            categories: diagram.map((x: any) => ({
              ...x,
              color: this.colors_news[x.name],
            })),
          },
          {
            title: "Тип трафика на витрине за последние 7&nbsp;дней",
            chart_title: "Все устройства",
            categories: traffic.map((x: any) => ({
              ...x,
              color: this.colors_devices[x.name],
            })),
          },
        ];

        this.campaigns = [
          {
            title: "Количество потоков",
            amount: campaigns.length,
            currency: "",
          },
        ];
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }

      this.loading = false;
    },
  },
});
