<template>
  <div class="account-info chart">
    <p class="account-info__item--tag item-title">
      Аналитика
    </p>
    <h3 class="chart__title" v-html="title" />
    <div class="chart__diagram chart__news">
      <p class="chart__text">
        {{ chartTitle }}
      </p>
      <svg viewBox="0 0 42 42">
        <circle class="chart__center" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle>
        <circle class="chart__ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#d2d3d4" stroke-width="5"></circle>
        <circle
          class="diagram__segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke-width="5"
          v-for="(part, i) of chartsData"
          :key="i"
          :stroke-dasharray="part.value + ' ' + (100 - part.value)"
          :stroke-dashoffset="this.offsets[i]"
          :stroke="part.color"
        />
      </svg>
    </div>
    <ul class="chart__list" >
      <li class="chart__list--item" v-for="(item, i) of chartsData" :key="i">
        <span class="dot" :style="{ backgroundColor: item.color }"></span>
        <span class="name">{{ item.name }}</span>
        <span class="value">{{ rounded(item.value) }} %</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    chartsData: Array,
    title: String,
    chartTitle: String,
  },
  data() {
    return {
      offsets: [],
      startOffset: 25
    };
  },
  methods: {
    rounded(value) {
      return parseFloat(value).toFixed(1)
    }
  },
  mounted() {
    let parts = this.chartsData;

    parts.forEach((part, i) => {
      if (i === 0) {
        this.offsets.push(this.startOffset);
      } else if (i === 1) {
        this.offsets.push(100 - this.chartsData[i - 1].value + this.startOffset);
      } else {
        this.offsets.push(this.offsets[i - 1] + (100 - this.chartsData[i - 1].value))
      }
    });
  },
};
</script>

<style lang="scss">
.chart__list--item .value {
  font-size: 16px;
}

.chart__list--item:not(:last-child) {
  margin-bottom: 15px;
}

.chart__list--item .dot {
  margin-right: 10px;
}
</style>
